import moment from "moment";

export default function useUtilChanges() {
    function replacePlaca(placa: string) {
        if(!placa) return;

        placa = placa.trim().replace(/\s+/g, '')

        // AAA-1A11 ou AAA-1111 -> ignora case sensitive
        const formatoPlacaTravessao = /^[a-zA-Z]{3}\-[0-9][0-9a-zA-Z][0-9]{2}$/g;
        if(formatoPlacaTravessao.test(placa)){
            let termosPlaca = placa.split("-");
            return `XXX-${termosPlaca[1]}`;
        }
        
        // AAA1A11 ou AAA1111 -> ignora case sensitive
        const formatoPlacaSemTravessao = /^[a-zA-Z]{3}[0-9][0-9a-zA-Z][0-9]{2}$/g;
        if(formatoPlacaSemTravessao.test(placa)){
            let endPlaca = placa.substring(3);
            return `XXX-${endPlaca}`;
        }

        return placa;
    }

    function trataNomeConsultor(texto: string): string {
        try {
            const nome = texto.split('.')[1];            
            return nome ?? texto;
        } catch (error) {
            return texto;
        }
    }

    const cutChassi = (chassi: string, slice: number = 6, additionalText: string = ''): string => {
        try {
            if (typeof chassi !== 'string') {
                throw new Error('Invalid input: chassi must be a string');
            }
    
            return additionalText + chassi.slice(slice);
        } catch (error) {

            if (error instanceof Error) {
                console.error(error.message);
            } else {
                console.error('An unknown error occurred');
            }

            return '';
        }
    }
    
    const geraDefaultTimes = (minTime = "07:00", maxTime = "19:00", step = 60, showLastTime = false) => {
        const refTrueLastTime = moment(maxTime, "HH:mm");
        let refLastTime = refTrueLastTime.clone();
        if(!showLastTime){
          refLastTime.subtract(step, "minutes");
        }
  
        let auxDefaultTimes:any = [];
        let auxTime = moment(minTime, "HH:mm");
        auxTime.subtract(step, "minutes");
  
        while(auxTime.clone().isBefore(refLastTime)) {
          auxTime.add(step, "minutes");
          const nextTime = auxTime.clone();
  
          if(nextTime.clone().isBefore(refTrueLastTime) || (showLastTime && nextTime.isSame(refTrueLastTime))){
            auxDefaultTimes.push({
              text: nextTime.format("HH:mm"),
              value: Number(nextTime.format("HH")),
              disabled: false
            });
          }
        }
  
        return auxDefaultTimes;
    }  

    //validação de CNPJ Rules     
    const validarCNPJ = (rule: any, cnpj: any, callback: any) => {
        cnpj = cnpj.replace(/[^\d]+/g,'');
  
        if(cnpj == '') {
          callback(new Error("Favor inserir CNPJ válido"));
        }
  
        if (cnpj.length != 14) {
          callback(new Error("Favor inserir CNPJ válido"));
        }
  
        if (cnpj == "00000000000000" || 
          cnpj == "11111111111111" || 
          cnpj == "22222222222222" || 
          cnpj == "33333333333333" || 
          cnpj == "44444444444444" || 
          cnpj == "55555555555555" || 
          cnpj == "66666666666666" || 
          cnpj == "77777777777777" || 
          cnpj == "88888888888888" || 
          cnpj == "99999999999999") {
            callback(new Error("Favor inserir CNPJ válido")); // LINHA 21
        }
  
        // Valida DVs LINHA 23 -
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0,tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2) {
            pos = 9;
          }
        }
  
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0)){
          callback(new Error("Favor inserir CNPJ válido"));
        }
  
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2) {
            pos = 9;
          }
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1)) {
          callback(new Error("Favor inserir CNPJ válido")); // LINHA 49
        }
  
        callback();
        return "CNPJ(" + cnpj + ") - Válido";
    }

     //validação email Rules
    const validateEmail = (
        rule: any,
        formConcessionaria: any,
        callback: any
      ) => {
        let usuario = formConcessionaria.substring(
          0,
          formConcessionaria.indexOf("@")
        );
        let dominio = formConcessionaria.substring(
          formConcessionaria.indexOf("@") + 1,
          formConcessionaria.length
        );
       
        if (
          usuario.length >= 1 &&
          dominio.length >= 3 &&
          usuario.search("@") == -1 &&
          dominio.search("@") == -1 &&
          usuario.search(" ") == -1 &&
          dominio.search(" ") == -1 &&
          dominio.search(".") != -1 &&
          dominio.indexOf(".") >= 1 &&
          dominio.lastIndexOf(".") < dominio.length - 1
        ) {
          return true;
        } else {
          callback(new Error("Favor inserir email válido"));
        }
    }

    const validateEmailTag = (email: any) => {
        let usuario = email.substring(0, email.indexOf("@"));
        let dominio = email.substring(email.indexOf("@") + 1, email.length);
        
        if (
          usuario.length >= 1 &&
          dominio.length >= 3 &&
          usuario.search("@") == -1 &&
          dominio.search("@") == -1 &&
          usuario.search(" ") == -1 &&
          dominio.search(" ") == -1 &&
          dominio.search(".") != -1 &&
          dominio.indexOf(".") >= 1 &&
          dominio.lastIndexOf(".") < dominio.length - 1
        ) {
          return true;
        }
  
        return false;
    }  

    return { 
        replacePlaca, 
        trataNomeConsultor , 
        cutChassi , 
        geraDefaultTimes,
        validarCNPJ,
        validateEmail,
        validateEmailTag
    };
}
